import emailList from "../official_email_list.json"
import { officialEmailService } from '../services/officialEmailService';

function checkEmailDomain(email, domain) {
    if (!email) return false
  let emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return false;
  }

  let emailDomain = emailParts[1];

  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomain = extractBaseDomain(urlDomain);

  return emailDomain === baseDomain;
}

// Extract base domain properly handling multi-part TLDs
function extractBaseDomain(hostname) {
  // List of known multi-part TLDs
  const multiPartTlds = [
    'co.uk', 'co.id', 'co.jp', 'co.nz', 'co.za', 'co.th', 'co.kr', 'co.tz',
    'com.au', 'com.br', 'com.sg', 'com.my', 'com.tr', 'com.mx', 'com.ph',
    'org.uk', 'net.au', 'ac.uk', 'gov.uk', 'edu.au', 'or.id', 'ac.id',
    'go.id', 'sch.id', 'web.id', 'net.id', 'mil.id', 'biz.id'
  ];
  
  const parts = hostname.split('.');
  
  // Check for multi-part TLDs first
  for (const tld of multiPartTlds) {
    const tldParts = tld.split('.');
    if (parts.length > tldParts.length) {
      const domainEnd = parts.slice(-tldParts.length).join('.');
      if (domainEnd === tld) {
        // Return the domain name + the multi-part TLD
        return parts.slice(-tldParts.length - 1).join('.');
      }
    }
  }
  
  // Default case: use the last two parts
  if (parts.length >= 2) {
    return parts.slice(-2).join('.');
  }
  
  return hostname;
}

export function getBaseDomain(domain) {
  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return '';
  }

  // Get the base domain from the urlDomain using the improved function
  return extractBaseDomain(urlDomain);
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidDomain(domain) {
  const domainReg = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/?|$)/;
  return domainReg.test(domain);
}

async function findVerificationEmails(domain) {
  return await officialEmailService.getVerificationEmails(domain);
}

export async function checkEmailDomainWithList(email, domain) {
  if (!email || !domain) return false;
  
  let baseDomain = getBaseDomain(domain);
  if (!baseDomain) return false;

  // Get the verification emails from the officialEmailService
  let verificationEmails = await findVerificationEmails(baseDomain);
  verificationEmails.push(baseDomain)

  // Extract the domain from the provided email
  let emailDomain = email.split('@')[1];

  // Check if the email is in the list of verification emails
  return verificationEmails.includes(emailDomain);
}

export async function getBaseDomainWithList(domain) {
  if (!domain) return { error: 'No domain provided' };

  let urlDomain;
  try {
    if (!/^https?:\/\//i.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (error) {
    return { error: 'Invalid domain' };
  }

  // Get the base domain from the urlDomain using the improved function
  const baseDomain = extractBaseDomain(urlDomain);

  try {
    // Find the verification email domains
    let verificationEmails = await findVerificationEmails(baseDomain);
    verificationEmails.push(baseDomain)

    // Return all verification emails and the base domain
    return {
      verificationEmails,
      baseDomain
    };
  } catch (error) {
    return { error: 'Failed to fetch verification emails', baseDomain };
  }
}

export default checkEmailDomain;
