/** @type {import('next').NextConfig} */

module.exports = {
  productionBrowserSourceMaps: true,
  reactStrictMode: true,
  experimental: {
    swcPlugins: [["@swc-jotai/react-refresh", {}]],
  },
  images: {
    remotePatterns: [
      { protocol: "https", hostname: "via.placeholder.com" },
      { protocol: "https", hostname: "picsum.photos" },
      { protocol: "https", hostname: "source.unsplash.com" },
      {
        protocol: "https",
        hostname: "lucidityinsights.s3.eu-west-2.amazonaws.com",
      },
      { protocol: "https", hostname: "lh3.googleusercontent.com" },
      { protocol: "https", hostname: "images.unsplash.com" },
      { protocol: "https", hostname: "d1477w6a38me2o.cloudfront.net" },
      { protocol: "https", hostname: "lucidityinsights.com" },
      { protocol: "https", hostname: "staging.lucidityinsights.com" },
      { protocol: "https", hostname: "images.pexels.com" },
      { protocol: "https", hostname: "cdn.lucidityinsights.com" },
    ],
    minimumCacheTTL: 60,
  },
  env: {
    API_BASE_URL: process.env.API_BASE_URL,
    APP_URL: process.env.APP_URL,
    BASE_URL: process.env.BASE_URL,
    API_VERSION: "/api/v1",
    ENV: process.env.ENV,
  },
  webpack(config, options) {
    const { isServer } = options;
    config.module.rules.push({
      test: /\.(ogg|mp3|wav|mpe?g)$/i,
      exclude: config.exclude,
      use: [
        {
          loader: require.resolve("url-loader"),
          options: {
            limit: config.inlineImageLimit,
            fallback: require.resolve("file-loader"),
            publicPath: `${config.assetPrefix}/_next/static/images/`,
            outputPath: `${isServer ? "../" : ""}static/images/`,
            name: "[name]-[hash].[ext]",
            esModule: config.esModule || false,
          },
        },
      ],
    });
    return config;
  },
};
